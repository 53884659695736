* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.hidden-scroll::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
a {
  text-decoration: none;
}
.round-loading {
  animation: round-loading 0.5s ease-in-out infinite;
}
.no-resize {
  resize: none;
}
@keyframes round-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
